import {registerApplication, start} from 'single-spa';

export function startApplication(_widgetTree, decorators = []) {
  window.CUBEJS_API_URL = process.env.CUBEJS_API_URL || '/gateway/bi/cubejs-api/v1';
  start();
  const rendererPromise = System.import('@netvision/lib-widget-renderer');
  return System.import('@netvision/lib-merge-widget-tree').then(({applyUserSetting}) => {
    applyUserSetting(_widgetTree).then((widgetTree) => {
      registerApplication(
        'renderer',
        () => rendererPromise,
        () => true,
        {
          widgetTree,
          decorators
        }
      );

      start();
    });
  });
}
